import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { GameGuard } from './guards/game.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule),
    canActivate: [AuthGuard]
  },


  {
    path: 'login/:evento_uuid',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    canActivate: []
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'cropper',
    loadChildren: () => import('./pages/cropper/cropper.module').then( m => m.CropperPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'game/:evento_uuid',
    loadChildren: () => import('./pages/game/game.module').then( m => m.GamePageModule),
    canActivate: [AuthGuard,GameGuard]
  },
  {
    path: 'chat/:chat_key/:pretendente_id',
    loadChildren: () => import('./pages/chat/chat.module').then( m => m.ChatPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'error',
    loadChildren: () => import('./pages/error/error.module').then( m => m.ErrorPageModule)
  },
  {path: '**', redirectTo: '/home'},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { createClient, SupabaseClient } from '@supabase/supabase-js';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Checkin } from 'src/app/model/checkin';
import { Database } from 'src/app/types/supabase';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CheckinService {
  private supabase: SupabaseClient;
  constructor(public http: HttpClient){
    this.supabase = createClient<Database>(
      environment.supabaseUrl,
      environment.supabaseKey
    );
  }

  async postCheckin(envento_uuid: string){
    return await this.supabase.from('checkin').insert({
        evento_uuid: envento_uuid,
        user_id: await (await this.supabase.auth.getUser()).data.user.id
      }
    );}
    async checkin(evento_uuid: string){
      const { data, error } = await this.supabase.functions.invoke('checkin', {
        body: { name: 'Functions' },
      });
      console.log(data);
    }
  async getMyCheckins(){
   const checkins = await this.supabase
  .from('checkin')
  .select(`
    *,
    evento(*)
  `)
  .eq('active', true)
  .eq('user_id', (await this.supabase.auth.getUser()).data.user.id)
  .order('checkin_id',{  ascending: false} )
  .limit(1);
  // console.log(checkins.data[0]);
  return  checkins.data[0];
  }

  async getCheckinsAtEvent(eventoId: number, gender_lookup: string, gender_search: string, last_id: number){
    const checkins = await this.supabase
    .from('checkin')
    .select(`
      *,
      profile(*)
    `)
    .eq('active', true)
    .eq('evento_id', eventoId)
    .eq('gender_search', gender_search)
    .eq('gender_lookup', gender_lookup)
    .gt('checkin_id', last_id)
    .limit(15);
  }


}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ProfilemenuComponent } from './profilemenu/profilemenu.component';
import { NavbarComponent } from './navbar/navbar.component';
import { MenuComponent } from './menu/menu.component';
import { ChatlistComponent } from './chatlist/chatlist.component';



@NgModule({
  declarations: [ProfilemenuComponent, NavbarComponent, MenuComponent, ChatlistComponent],
  imports: [
    CommonModule,
    IonicModule.forRoot()
  ],
  exports:[ProfilemenuComponent, NavbarComponent, MenuComponent, ChatlistComponent]
})
export class SharedModule { }

/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { Ficada } from 'src/app/model/ficada';
import { chat_message } from 'src/app/model/firebase/chatMessage';
import { ChatService } from 'src/app/services/chat/chat.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import * as _  from 'lodash';
import { Profile } from 'src/app/model/profile';
@Component({
  selector: 'app-ficada-chat-item',
  templateUrl: './ficada-chat-item.component.html',
  styleUrls: ['./ficada-chat-item.component.scss'],
})
export class FicadaChatItemComponent implements OnInit {
  @Input() ficada: Ficada;
  chats: chat_message[];
  last_chat: chat_message;
  read=true;
  pretendente: any;

  constructor(public profileService: ProfileService,
              public router: Router,
              public notificationService: NotificationService,
              private menu: MenuController,
              private chatService: ChatService) { }

  ngOnInit() {
 this.profileService.getProfile(this.ficada.from_id).then(response => {
    this.pretendente = response.data;
    // this.chatService.watch(this.ficada.chat_id );
    this.chatService.callbackResponse.subscribe((payload) => {
      this.handleMessage(payload);
    });
   });
  }
goToChat(){
  this.menu.close();
  this.read=true;
  this.menu.close('true');
  this.router.navigateByUrl(`/chat/${this.ficada.chat_id}/${this.pretendente.id}`);
}
handleMessage(payload){
  if(this.ficada.chat_id === payload.chat_id){
    this.chats = _.union([payload], this.chats);
    const lastReadChat = localStorage.getItem(this.ficada.chat_id);
    this.last_chat =payload;
    if(this.last_chat.user_id === this.pretendente.id && lastReadChat !== this.last_chat.chat_id){
                  localStorage.setItem(this.ficada.chat_id,this.last_chat.chat_id);
                  this.read= false;
                  this.notificationService.counter ++;
              }

  }
}
}

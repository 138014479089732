// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'ficada-f7ba9',
    appId: '1:1003372387881:web:5a556bb2ba733cc0b9c638',
    storageBucket: 'ficada-f7ba9.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyC6xNEIOHozWX5TkbI2TtYOJbHhhdf8wyQ',
    authDomain: 'ficada-f7ba9.firebaseapp.com',
    messagingSenderId: '1003372387881',
    measurementId: 'G-EH989GMM82',
  },
  production: false,
  api: 'https://postgrest-r6ydc2axnq-uc.a.run.app/',
  front:'https://ficada.com.br',
    supabaseUrl: 'https://cetbmvtgccdjyaqwwsah.supabase.co',
    // eslint-disable-next-line max-len
    supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlhdCI6MTY0MzgyODQxMywiZXhwIjoxOTU5NDA0NDEzfQ.9ziCB-b5CpZQNqIMUSSh5HHqsW_jxDITFjq_wBQDTcI',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

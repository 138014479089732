import { Component, Input, OnInit } from '@angular/core';
import { Pretendente } from 'src/app/model/pretendente';
import { Profile } from 'src/app/model/profile';
import { CurtidaService } from 'src/app/services/curtida/curtida.service';
import ls from 'localstorage-slim';
import { SupabaseService } from 'src/app/services/supabase/supabase.service';
import { ProfileService } from 'src/app/services/profile/profile.service';

@Component({
  selector: 'app-ficada-card',
  templateUrl: './ficada-card.component.html',
  styleUrls: ['./ficada-card.component.scss'],
})
export class FicadaCardComponent implements OnInit {
@Input() pretendente = {} as Pretendente;
@Input() profile = {} as Profile;
@Input() eventoId: number;
myProfile;
wasclicked = false;
  constructor(private curtidaService: CurtidaService,
              private readonly supabase: SupabaseService,
              private profileService: ProfileService) { }

  ngOnInit() {
    this.profileService.myprofile.subscribe( myprofile => {
      this.myProfile = myprofile;
    });
    if(ls.get(`C-E${this.eventoId}P${this.pretendente.id}`)){
        this.wasclicked=true;
    }
  }
  curtida(pretendente: Pretendente){
    this.wasclicked=true;
    this.curtidaService.curtir(this.myProfile.id, this.pretendente.id,this.eventoId);
    //save item to local storage in window
    console.log(`C-E${this.eventoId}P${this.pretendente.id}`);
    ls.set(`C-E${this.eventoId}P${this.pretendente.id}`, true,{ttl:604800});
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { CheckinService } from 'src/app/services/checkin/checkin.service';
import { ProfileService } from 'src/app/services/profile/profile.service';

@Component({
  selector: 'app-evento-card',
  templateUrl: './evento-card.component.html',
  styleUrls: ['./evento-card.component.scss'],
})
export class EventoCardComponent implements OnInit {
  checkin;
  constructor(public profileService: ProfileService,
               public checinService: CheckinService,
                public route: Router,
                 private menuController: MenuController) { }

  ngOnInit() {
    this.profileService.myprofile.subscribe((profile) => {
      if(profile){
        this.getCheckin();
      }
    });
  }
  gotToEvent(){
    this.menuController.close();
    this.route.navigateByUrl(`/game/${this.checkin.evento.evento_id}`);
  }
  async getCheckin(){
    this.checkin = await this.checinService.getMyCheckins();
  }

}

/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { Profile } from 'src/app/model/profile';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { SupabaseService } from 'src/app/services/supabase/supabase.service';

@Component({
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss'],
})
export class ProfileCardComponent implements OnInit {
loading =false;

  profile = {} as Profile;

  constructor(
    private menu: MenuController,
     private route: Router,
      private profileService: ProfileService) { }

  ngOnInit() {
    this.profileService.myprofile.subscribe((profile) => {
      this.profile = profile;
    });
  }
  gotoProfile(){
    this.menu.close();
    this.route.navigateByUrl('/profile');
  }
}

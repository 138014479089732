import { Component, OnInit } from '@angular/core';
import { NotificationService } from './services/notification/notification.service';
import { ProfileService } from './services/profile/profile.service';
import { SupabaseService } from './services/supabase/supabase.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  session ;
  watcher =null;

  constructor(private readonly supabase: SupabaseService,
    private readonly profileService: ProfileService,
    private notificationService: NotificationService) {}
  ngOnInit() {
    this.supabase.authChanges((_, session) =>{
      (this.session = session);
      if (session) {
        this.profileService.initateProfile(session.user.id);
        this.watchNotification(session.user.id);
      }
    } );
  }
  async watchNotification(profileId){
    if(!this.watcher){
    console.log('this.watchNotification');
    this.watcher= await this.notificationService.watch(profileId);
    }
}
}

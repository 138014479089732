import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentsRoutingModule } from './components-routing.module';
import { IonicModule } from '@ionic/angular';
import { ProfileCardComponent } from './profile-card/profile-card.component';
import { LogoutButtonComponent } from './logout-button/logout-button.component';
import { PicbuttonComponent } from './picbutton/picbutton.component';
import { FicadaCardComponent } from './ficada-card/ficada-card.component';
import { FicadaHeaderComponent } from './ficada-header/ficada-header.component';
import { FicadaListComponent } from './ficada-list/ficada-list.component';
import { FicadaChatItemComponent } from './ficada-list/ficada-chat-item/ficada-chat-item.component';
import { EventoCardComponent } from './evento-card/evento-card.component';
import { CompleteProfileComponent } from './complete-profile/complete-profile.component';
import { EmptyGameComponent } from './empty-game/empty-game.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ProfileBannerComponent } from './profile-banner/profile-banner.component';
import { AvatarImageComponent } from './avatar-image/avatar-image.component';
import { ChatHeaderComponent } from './chat-header/chat-header.component';
@NgModule({
  declarations: [
    ProfileCardComponent,
    LogoutButtonComponent,
    PicbuttonComponent,
    FicadaCardComponent,
    FicadaHeaderComponent,
    FicadaListComponent,
    FicadaChatItemComponent,
    EventoCardComponent,
    CompleteProfileComponent,
    EmptyGameComponent,
    ProfileBannerComponent,
    AvatarImageComponent,
    ChatHeaderComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    ComponentsRoutingModule,
    ImageCropperModule
    ],
  exports:[
    ProfileCardComponent,
    LogoutButtonComponent,
    PicbuttonComponent,
    FicadaCardComponent,
    FicadaHeaderComponent,
    FicadaListComponent,
    EventoCardComponent,
    CompleteProfileComponent,
    EmptyGameComponent,
    ProfileBannerComponent,
    AvatarImageComponent,
    ChatHeaderComponent
]
})
export class ComponentsModule { }

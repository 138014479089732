/* eslint-disable @typescript-eslint/no-shadow */
import { Component, Input, OnInit } from '@angular/core';
import {  AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { UserDoc } from 'src/app/model/firebase/userDoc';
import { Profile } from 'src/app/model/profile';
import { NotificationService } from 'src/app/services/notification/notification.service';
//import lodash from 'lodash';
import * as _ from 'lodash';
import { SupabaseService } from 'src/app/services/supabase/supabase.service';

@Component({
  selector: 'app-ficada-header',
  templateUrl: './ficada-header.component.html',
  styleUrls: ['./ficada-header.component.scss'],
})

export class FicadaHeaderComponent implements OnInit {
  @Input()
  title: string;
  @Input()
  internal = false;
  @Input()
  logo = false;
  profile ={} as Profile;
  session;
  user: Observable<UserDoc>;
  private userDoc: AngularFirestoreDocument<UserDoc>;


  constructor(private readonly supabase: SupabaseService, public notificationService: NotificationService) {}


  ngOnInit() {
    this.supabase.authChanges((_, session) => (this.session = session));
    this.notificationService.counter =0;
    // this.profileService.getProfile().subscribe(profs =>{
    //   this.profile = profs[0];
    //   this.userDoc = this.afs.doc<UserDoc>(`users/${this.profile.firebase_id}`);
    //   this.userDoc.valueChanges().subscribe(userDoc =>{
    //     console.log(userDoc);
    //     let chatsOld = [];
    //     if(localStorage.getItem('chats') && localStorage.getItem('chats') !== 'undefined'){
    //       console.log(localStorage.getItem('chats'));
    //       chatsOld = JSON.parse(localStorage.getItem('chats'));
    //     }
    //     if(chatsOld && userDoc.ficadas){
    //       this.notificationService.counter = userDoc?.ficadas.length - chatsOld.length;
    //       if(this.notificationService.counter > 0) {this.notificationService.presentToast();}
    //     }
    //     localStorage.setItem('chats', JSON.stringify(userDoc.ficadas));

    //   });

  //  });
  }

}

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Profile } from 'src/app/model/profile';
import { ImageService } from 'src/app/services/image.service';

@Component({
  selector: 'app-picbutton',
  templateUrl: './picbutton.component.html',
  styleUrls: ['./picbutton.component.scss'],
})
export class PicbuttonComponent implements OnInit {
  @ViewChild('imageInput') imageInputRef: ElementRef;
  @Input() avatar: string;
  @Input() showBanner: Boolean;
   emptyPath ='this.src=\'../../../assets/user-empty.jpg\'';
  constructor(public imageService: ImageService, public router: Router) { }

  ngOnInit(): void {
  }
  imageSelect(){
    console.log('Image select');
    this.imageInputRef.nativeElement.click();
  }
  fileChangeEvent(imageInput: any){
    const file: File = imageInput.files[0];
    this.imageService.setRawImage(file);
    this.router.navigateByUrl('/cropper');
  }
  onImgError(event){
    event.target.src = './assets/imgs/altImg.png';
   //Do other stuff with the event.target
   }
}

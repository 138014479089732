import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Profile } from 'src/app/model/profile';

@Component({
  selector: 'app-avatar-image',
  templateUrl: './avatar-image.component.html',
  styleUrls: ['./avatar-image.component.scss'],
})
export class AvatarImageComponent implements OnInit {
@Input() profile: Profile;
@Input() showEdit: boolean;
  constructor(private router: Router) { }

  ngOnInit() {}
  cameraClick(){
    console.log('camera click');
    this.router.navigateByUrl('/cropper');
  }
}

import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { SupabaseService } from 'src/app/services/supabase/supabase.service';

@Component({
  selector: 'app-logout-button',
  templateUrl: './logout-button.component.html',
  styleUrls: ['./logout-button.component.scss'],
})
export class LogoutButtonComponent implements OnInit {
  session;

  constructor(public auth: AngularFireAuth, private router: Router,private menu: MenuController, private supabase: SupabaseService ) { }

  ngOnInit() {
    this.supabase.authChanges((_, session) => (this.session = session));
  }
  logout(){

    return this.supabase.signOut().then(() => {
      this.menu.close();
      this.router.navigateByUrl('/');
    });
  }
}

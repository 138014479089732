import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  rawImage: any;
  profilePic: any ;
  imageChangedEvent: any = '';
  constructor(private afStorage: AngularFireStorage) { }
  setRawImage(imageInput: any){
    console.log('set Image Raw', imageInput);
    this.rawImage = imageInput;
  }

  async uploadFile(filePath: string,fileToUpload) {
    return (await this.afStorage.upload(filePath, fileToUpload)).ref;
  }
}

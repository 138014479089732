import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { chat_message } from 'src/app/model/firebase/chatMessage';

@Pipe({
  name: 'chatFormat'
})
export class ChatReceivedPipe implements PipeTransform {

  transform(value: chat_message, name?: String ): unknown {
    let response = formatDate(value.created_at,'dd/MM/yyyy HH:mm','en-US');
    if(name){
       response = name +' ' +response;
    }
    return response;
  }

}

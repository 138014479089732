import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createClient, SupabaseClient } from '@supabase/supabase-js';
import { Observable  } from 'rxjs';
import { Ficada } from 'src/app/model/ficada';
import { Database } from 'src/app/types/supabase';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FicadaService {
  ficadas = [] as Ficada[];
  private supabase: SupabaseClient;

  constructor(protected http: HttpClient) {
    this.supabase = createClient<Database>(
      environment.supabaseUrl,
      environment.supabaseKey
    );
  }
  async get(profileId: string){
    console.log('profileId',profileId);
    return await this.supabase
    .from('ficada')
    .select('*')
    .eq('to_id',profileId);
   }

   async watch(profileId: string, callback: any){
    return this.supabase
    .channel('ficada')
    .on(
      'postgres_changes',
      {
        event: '*',
        schema: 'public',
        table: 'ficada',
        filter: `to_id=eq.${profileId}`,
      },
      (payload) =>{
        callback(payload);
      }
    )
    .subscribe();
  }
}



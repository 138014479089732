/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { createClient, SupabaseClient } from '@supabase/supabase-js';
import { BehaviorSubject, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { Profile } from 'src/app/model/profile';
import { Database } from 'src/app/types/supabase';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  myprofile = new BehaviorSubject<any|null>(null);
  private supabase: SupabaseClient;

  constructor() {
    this.supabase = createClient<Database>(
      environment.supabaseUrl,
      environment.supabaseKey
    );
  }
 async getUser() {
    return await this.supabase.auth.getUser();
  }
  async getProfile(userId) {
    return await this.supabase
      .from('profile')
      .select()
      .eq('id', userId)
      .single();
  }
  initateProfile(userId: any) {
    return this.supabase
      .from('profile')
      .select()
      .eq('id', userId)
      .single().then(({ data, error }) => {
        if(!error && data){
        this.myprofile.next(data);
        }else{
          console.log({error});
        }
      });
  }

  updateProfile(profile: Profile, userId: any) {
    // console.log(profile);
    const update = {
      ...profile,
      id: userId,
      updated_at: new Date(),
    };

    return this.supabase.from('profile').upsert(update);
  }
  async uploadAvatar(filePath: string, file: Blob, userId: any) {
   const upload= await this.supabase
  .storage
  .from(`pictures/${userId}`)
  .upload(filePath, file, {
    cacheControl: '3600',
    upsert: false
  });
  const url =`${environment.supabaseUrl}/storage/v1/object/public/pictures/${userId}/${upload.data.path}`;
   return url;
  }
  checkIfProfileIsComplete(profile: any) {
    // console.log('profile',profile);
    if(profile.avatar &&
      profile.gender_label &&
      profile.name &&
      profile.gender_label &&
      profile.gender_lookup &&
      profile.gender_search){
        return true;
      }
      return false;
  }
}

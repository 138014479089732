/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { Ficada } from 'src/app/model/ficada';
import { UserDoc } from 'src/app/model/firebase/userDoc';
import { Profile } from 'src/app/model/profile';
import { FicadaService } from 'src/app/services/ficada/ficada.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { SupabaseService } from 'src/app/services/supabase/supabase.service';
import * as _ from 'lodash';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-ficada-list',
  templateUrl: './ficada-list.component.html',
  styleUrls: ['./ficada-list.component.scss'],
})

export class FicadaListComponent implements OnInit {
  loading= false;
  ficadas = [] as any[];
  counter =0;
  profile = {} as Profile;
  watcher =null;
  constructor(private profileService: ProfileService,
              private ficadaService: FicadaService,
              private notificationService: NotificationService,
              public toastController: ToastController) { }
  ngOnInit() {
    this.profileService.myprofile.subscribe((profile) => {
      if(profile){
      this.profile = profile;
      this.watchFicadas(profile.id);
      if(this.ficadas.length === 0){
       this.getFicadas();
      }
    }
    });
}
  async getFicadas() {
    const ficadasResponse =await (await this.ficadaService.get(this.profile.id)).data;
    console.log('ficadasResponse',ficadasResponse);
    if(ficadasResponse){
    this.ficadas = ficadasResponse;
    }
  }
  async watchFicadas(profileId){
    if(!this.watcher){
    console.log('this.watchFicadas');
    this.watcher= await this.ficadaService.watch(profileId,this.handleChanges);
    }
  //  this.ficadas = response.data;
  //  console.log(this.ficadas);
  }
  handleChanges=async (payload: any)=>{
    console.log('payload',payload);
    if(payload.eventType === 'INSERT'){
      this.ficadas = [...this.ficadas, payload.new];
      this.notificationService.presentToast();
    }else if(payload.eventType === 'DELETE'){
      _.remove(this.ficadas, (ficada) => ficada.id === payload.old.id);
    }
  console.log('novas ficadas',this.ficadas);
  };
}

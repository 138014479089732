import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CheckinService } from '../services/checkin/checkin.service';
import { EventoService } from '../services/evento/evento.service';

@Injectable({
  providedIn: 'root'
})
export class GameGuard implements CanActivate {
  constructor(private router: Router, private eventoService: EventoService){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> {
    return new Observable((observer) => {
      this.eventoService.getEvento(route.params.evento_uuid).then((evento) => {
        if(evento){
          observer.next(true);
        }else{
          this.router.navigate(['/']);
          observer.next(false);
        }
      });
    });
  }}

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-banner',
  templateUrl: './profile-banner.component.html',
  styleUrls: ['./profile-banner.component.scss'],
})
export class ProfileBannerComponent implements OnInit {
  @Input() avatar: string;

  constructor(private router: Router) { }

  ngOnInit() {}

  cameraClick(){
    this.router.navigateByUrl('/cropper');
  }

}

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';

import { from, Observable, of } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { switchMap } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

export interface Key { apitoken: string }
@Injectable({
  providedIn: 'root'
})
export class TokeninterceptorService implements HttpInterceptor{
  private itemDoc: AngularFirestoreDocument<Key>;

  constructor(public auth: AngularFireAuth,private afs: AngularFirestore) {

   }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   let apitoken =localStorage.getItem('apitoken');
   if(!apitoken){
    const userId =this.auth.idTokenResult;
    const userKey = userId
    .pipe(
      switchMap((value) => this.afs.doc<Key>(`keys/${value.claims.user_id}`).valueChanges()));
      userKey.subscribe(key =>{
        localStorage.setItem('apitoken',key.apitoken);
        apitoken= key.apitoken;
        // console.log('apitoken',apitoken);
        const headers = request.headers
                    .set('Authorization', 'Bearer ' + apitoken);
           const requestClone = request.clone({
             headers
            });
            // console.log('Request', requestClone);
          return next.handle(requestClone);
      });
    }else{
      // console.log('apitoken',apitoken);
      const headers = request.headers
                  .set('Authorization', 'Bearer ' + apitoken);
         const requestClone = request.clone({
           headers
          });
          // console.log('Request', requestClone);
        return next.handle(requestClone);
  }
}
  }


/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {
  AuthChangeEvent,
  createClient,
  Session,
  SupabaseClient,
} from '@supabase/supabase-js';
import { Profile } from 'src/app/model/profile';
import { Database } from 'src/app/types/supabase';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root',
})
export class SupabaseService {
  private supabase: SupabaseClient;

  constructor() {
    this.supabase = createClient<Database>(
      environment.supabaseUrl,
      environment.supabaseKey
    );
  }

  async getUser() {
    const {
      data: { session },
    } = await this.supabase.auth.getSession();
    const { user } = session;
    return user;
  }

  async getSession() {
    const {
      data: { session },
    } = await this.supabase.auth.getSession();
    return session;
  }

  authChanges(
    callback: (event: AuthChangeEvent, session: Session | null) => void
  ) {
    return this.supabase.auth.onAuthStateChange(callback);
  }

  async signIn(email: string, evento_uuid: string) {
   // return this.supabase.auth.signIn({ email });
   console.log(`${environment.front}/game/${evento_uuid}`);
    return await this.supabase.auth.signInWithOtp({ email, options: { emailRedirectTo: `${environment.front}/game/${evento_uuid}` } });
    }
  signOut() {
    return this.supabase.auth.signOut();
  }
  downLoadImage(path: string) {
    return this.supabase.storage.from('avatars').download(path);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Profile } from 'src/app/model/profile';

@Component({
  selector: 'app-complete-profile',
  templateUrl: './complete-profile.component.html',
  styleUrls: ['./complete-profile.component.scss'],
})
export class CompleteProfileComponent implements OnInit {
@Input() profile: Profile;


  constructor(private router: Router) { }

  ngOnInit() {}
  gotoProfile(){
    this.router.navigateByUrl('/profile');
}
}

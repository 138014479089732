/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createClient, SupabaseClient } from '@supabase/supabase-js';
import { Database } from 'src/app/types/supabase';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CurtidaService {
  private supabase: SupabaseClient;

  constructor( protected http: HttpClient) {
    this.supabase = createClient<Database>(
      environment.supabaseUrl,
      environment.supabaseKey
    );
  }

  async curtir(from: string,to: string, eventoId: number){
    return await this.supabase.from('curtida').insert(
    {
      from_id: from,
      evento_uuid: eventoId,
      to_id: to
    }
    );
  }
}

import { Injectable } from '@angular/core';
import { createClient, SupabaseClient } from '@supabase/supabase-js';
import { Subject } from 'rxjs';
import { chat_message } from 'src/app/model/firebase/chatMessage';
import { Database } from 'src/app/types/supabase';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  callbackResponse = new Subject();
  private supabase: SupabaseClient;

  constructor() {
    this.supabase = createClient<Database>(
      environment.supabaseUrl,
      environment.supabaseKey
    );
   }
   watch(chatId: string, userId: string, callback: any){
    console.log('chat_id',chatId);
   return this.supabase
  .channel('chat')
  .on(
    'postgres_changes',
    {
      event: 'UPDATE',
      schema: 'public',
      table: 'chat_message',
      filter: `chat_id=eq.${chatId}`,
    },
    (payload) =>{
      callback(payload, userId);
    }
  )
  .subscribe();
   }
   get(chatId: string){
    console.log( 'chat_id',chatId);
    return this.supabase
    .from(`chat_message`)
    .select(`*`)
    .eq('chat_id', chatId)
    .order('created_at', { ascending: true });
   }
   async send(chatMessage: chat_message){
    const { data, error } = await this.supabase
    .from('chat_message')
    .insert([chatMessage]);
   }
}

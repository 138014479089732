import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createClient, SupabaseClient } from '@supabase/supabase-js';
import { Database } from 'src/app/types/supabase';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventoService {
  private supabase: SupabaseClient;
  constructor(public http: HttpClient){
    this.supabase = createClient<Database>(
      environment.supabaseUrl,
      environment.supabaseKey
    );
  }
  async getEvento(eventoUuid: any){
    console.log(eventoUuid);
    const eventos =  await this.supabase
   .from('evento')
   .select(`*`)
   .eq('uuid', eventoUuid)
   .limit(1);
   return eventos.data[0];
   }
}

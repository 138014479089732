import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { createClient, SupabaseClient } from '@supabase/supabase-js';
import { Database } from 'src/app/types/supabase';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  counter =0;
  private supabase: SupabaseClient;
  constructor(public toastController: ToastController, private router: Router) {
    this.supabase = createClient<Database>(
      environment.supabaseUrl,
      environment.supabaseKey
    );
   }

  resetCounter(){
    console.log('zero counter');
    this.counter =0;
  }
  async presentToast() {
    console.log('present toast');
    const toast = await this.toastController.create({
      message: 'Looks like you have a new Ficada ',
      duration: 2000,
      icon: 'heart',
      color: 'danger'

    });
    toast.present();
  }
  async presentNotificationToast(msg: string) {
    console.log('present notification toast');
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      icon: 'calendar-outline',
      color: 'success'

    });
    toast.present();
  }
  async watch(profileId: string){
    return this.supabase
    .channel('notification')
    .on(
      'postgres_changes',
      {
        event: 'INSERT',
        schema: 'public',
        table: 'notification',
        filter: `profile_id=eq.${profileId}`,
      },
      async (payload) =>{
        console.log('NOTIFICATION !!!!!!!!!!!!!!!!!!!!!!',payload);
        this.presentNotificationToast('A festa acabou, até a próxima!');
         await this.supabase.auth.signOut();
         this.router.navigate(['/']);
      }
    )
    .subscribe();
  }
}
